import { ConnectionState } from "@/shared/components/connection-state";
import { BackdropLoading } from "@/shared/components/loadings/backdrop-loading";
import { PersonAdd } from "@mui/icons-material";
import {
  CircularProgress,
  Alert,
  Button,
  Typography,
  Fab,
} from "@mui/material";
import { Suspense, useState } from "react";
import { CardItem } from "./card-item";
import { AlertPausedQueue } from "./paused-queue-alert";
import { useWatchEstablishmentQueue } from "@/shared/hooks/queue/use-watch-establishment-queue";
import { isBefore, parseISO } from "date-fns";
import { useEstablishmentContext } from "@/contexts/establishment/hook";
import AttendingClientDrawer from "@/shared/drawers/attending-client-drawer";

export const QueueView = () => {
  const [attendingDrawer, setAttendingDrawer] = useState(false);
  const { establishment } = useEstablishmentContext();
  const { establishmentId, status: establishmentStatus } = establishment;

  const {
    establishmentQueue = [],
    connectionState,
    status,
    refetch,
  } = useWatchEstablishmentQueue({
    establishmentId,
  });

  const queues = establishmentQueue?.filter(
    ({ establishmentId }) => !!establishmentId
  );
  const servingQueue =
    queues?.filter((queue) => queue.status === "SERVING") ?? [];
  const waitingQueue =
    queues
      ?.filter((queue) => queue.status === "WAITING")
      .sort((a, b) =>
        isBefore(parseISO(a.createdAt), parseISO(b.createdAt)) ? -1 : 1
      ) ?? [];

  const openAttendingDrawer = () => {
    setAttendingDrawer(true);
  };

  const closeAttendingDrawer = () => {
    setAttendingDrawer(false);
  };

  return (
    <>
      <div className="mx-4">
        <ConnectionState state={connectionState ?? "DISCONNECTED"} />
      </div>

      <div className="flex flex-col gap-y-4 mb-20">
        <div className="mx-4">
          <Typography variant="h6">Clientes na fila</Typography>
        </div>

        <AlertPausedQueue />

        {status === "pending" ? <CircularProgress className="mx-auto" /> : null}

        {status === "error" ? (
          <Alert
            severity="error"
            action={
              <Button key={1} onClick={() => refetch()}>
                Recarregar fila
              </Button>
            }
          >
            Erro ao carregar a fila
          </Alert>
        ) : null}

        {status === "success" && !queues?.length ? (
          <Alert severity="info">Nenhum cliente na fila no momento.</Alert>
        ) : null}

        <div className="flex flex-col gap-y-8">
          {servingQueue.length ? (
            <div className="grid grid-cols-1 gap-y-4 mx-4">
              <Typography>
                {servingQueue.length} cliente(s) <b>em atendimento</b>
              </Typography>
              {servingQueue.map((item) => (
                <CardItem key={item.queueId} establishmentQueue={item} />
              ))}
            </div>
          ) : null}

          {waitingQueue.length ? (
            <div className="grid grid-cols-1 gap-y-4 mx-4">
              <Typography>
                {waitingQueue.length} cliente(s) <b>aguardando</b>
              </Typography>
              {waitingQueue.map((item) => (
                <CardItem key={item.queueId} establishmentQueue={item} />
              ))}
            </div>
          ) : null}
        </div>
      </div>

      <Fab
        color="primary"
        aria-label="add"
        className="bottom-20 right-4"
        style={{ position: "fixed" }}
        variant="extended"
        disabled={establishmentStatus === "CLOSED"}
        onClick={openAttendingDrawer}
      >
        <PersonAdd className="mr-1" />
        Adicionar
      </Fab>

      <Suspense fallback={<BackdropLoading />}>
        <AttendingClientDrawer
          attendingType="QUEUE"
          addedBy="company"
          establishment={establishment}
          open={!!attendingDrawer}
          onOpen={openAttendingDrawer}
          onClose={closeAttendingDrawer}
        />
      </Suspense>
    </>
  );
};
