import {
  transferBalance,
  TransferBalanceInput,
} from "@/shared/services/barx-api/establishments/payments/transfer-balance";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

type Variables = {} & TransferBalanceInput;

export const useTransferBalance = () =>
  useMutation<AxiosResponse, AxiosError, Variables>({
    mutationFn: transferBalance,
  });
