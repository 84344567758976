import { FullscreenLoading } from "@/shared/components/loadings/fullscreen-loading";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { Paper } from "@mui/material";
import { Suspense } from "react";
import { Link, Outlet } from "react-router-dom";
import BarxLightLogo from "/logos/barx-sem-fundo-fundo-prancheta-1.svg?url";
import BarxDarkLogo from "/logos/barx-sem-fundo-branco-prancheta-1.svg?url";

export function AuthLayout() {
  return (
    <Suspense fallback={<FullscreenLoading />}>
      <Paper
        elevation={0}
        className="min-h-screen flex flex-col justify-center gap-y-8"
      >
        <div className="flex justify-center">
          <Link to={ROUTES_PATH.AUTH} className="flex items-center">
            <picture className="w-[300px]">
              <source
                srcSet={BarxDarkLogo}
                media="(prefers-color-scheme: dark)"
              />
              <source
                srcSet={BarxLightLogo}
                media="(prefers-color-scheme: light)"
              />
              <img src={BarxDarkLogo} alt="Barx Logo" />
            </picture>
          </Link>
        </div>

        <Paper elevation={0} className="mt-4 px-2">
          <Outlet />
        </Paper>
      </Paper>
    </Suspense>
  );
}
