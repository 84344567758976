import { listEstablishmentQueue } from "@/shared/services/barx-api/establishments/queue/list";
import { useQuery, useQueryClient } from "@tanstack/react-query";

type GetEstablishmentQueueInput = {
  establishmentId: string;
};

export function useGetEstablishmentQueue(props: GetEstablishmentQueueInput) {
  const { establishmentId } = props;
  return useQuery({
    queryKey: buildQueryKey(props),
    queryFn: async () => listEstablishmentQueue({ establishmentId }),
  });
}

function buildQueryKey(props: GetEstablishmentQueueInput) {
  return ["establishment-queue", ...Object.values(props)];
}

export function useInvalidateGetEstablishmentQueue(
  props: GetEstablishmentQueueInput
) {
  const queryClient = useQueryClient();
  const queryKey = buildQueryKey(props);

  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey,
      }),
  };
}
