import { Add } from "@mui/icons-material";
import { Paper, Typography, Button } from "@mui/material";
import { useState } from "react";
import { DialogCreateSubAccount } from "./dialog-create-sub-account";

export const CardSignUpAsaas = () => {
  const [dialogCreateSubAccount, setDialogCreateSubAccount] = useState(false);

  const openDialogCreateSubAccount = () => {
    setDialogCreateSubAccount(true);
  };

  const closeDialogCreateSubAccount = () => {
    setDialogCreateSubAccount(false);
  };

  return (
    <>
      <Paper className="m-4 px-4 py-2 flex flex-col gap-y-6">
        <Typography fontWeight="500" fontSize="18px">
          Crie sua conta Asaas
        </Typography>

        <div className="w-full flex items-center justify-center gap-x-2">
          <picture>
            <source
              srcSet="/logos/barx-sem-fundo-branco-09.svg"
              media="(prefers-color-scheme: dark)"
              type="image/svg+xml"
            />
            <img
              className="w-[42px] mr-2"
              src="/logos/barx-sem-fundo-09.svg"
              alt="Logo da Barx"
            />
          </picture>
          <Add />
          <img
            src="https://atlas.asaas.com/v20.19.0/assets/images/logos/asaas-small-logo.svg"
            alt="Logo da Asaas"
          />
        </div>

        <Typography>
          Receba Pix dos clientes direto pela Barx através da sua conta Asaas.
        </Typography>

        <Typography
          component="a"
          target="_blank"
          href="https://www.asaas.com/"
          color="info"
          fontSize="14px"
        >
          Saiba mais sobre o Asaas.
        </Typography>

        <div className="w-full flex justify-end">
          <Button
            variant="contained"
            color="secondary"
            onClick={openDialogCreateSubAccount}
          >
            Começar a receber
          </Button>
        </div>
      </Paper>

      <DialogCreateSubAccount
        open={dialogCreateSubAccount}
        onClose={closeDialogCreateSubAccount}
      />
    </>
  );
};
