import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { TransferBalanceDialog } from "@/shared/dialogs/transfer-balance";
import { useGetBalance } from "@/shared/hooks/establishment/payments/use-get-balance";
import { toCurrency } from "@/shared/utils";
import { Button, Paper, Typography } from "@mui/material";
import { useState } from "react";

export const Balance = () => {
  const [transferBalanceDialog, setTransferBalanceDialog] = useState(false);

  const {
    establishment: { establishmentId },
  } = useEstablishmentContext();
  const {
    data: {
      data: { balance },
    },
  } = useGetBalance({ establishmentId });

  const openTransferBalanceDialog = () => setTransferBalanceDialog(true);
  const closeTransferBalanceDialog = () => setTransferBalanceDialog(false);

  return (
    <>
      <Paper className="mx-4 mt-4 p-4 flex items-center justify-between">
        <div className="flex flex-col">
          <Typography variant="subtitle2">Saldo em conta</Typography>
          <Typography variant="h6" fontWeight="bold">
            {toCurrency(balance * 100)}
          </Typography>
        </div>

        <Button
          disabled={!balance}
          variant="contained"
          color="secondary"
          className="w-max h-max"
          onClick={openTransferBalanceDialog}
        >
          Transferir
        </Button>
      </Paper>

      <TransferBalanceDialog
        open={transferBalanceDialog}
        onClose={closeTransferBalanceDialog}
      />
    </>
  );
};
