import { ObjectSchema, array, date, number, object, string } from "yup";
import { ServiceTypeInputs } from "./types";

export const serviceTypeSchema: ObjectSchema<ServiceTypeInputs> = object({
  name: string().required("Campo obrigatório"),
  amount: string().required("Campo obrigatório"),
  duration: date().required("Campo obrigatório"),
  consumedItems: array()
    .of(
      object({
        name: string().required("Campo obrigatório"),
        quantity: number()
          .typeError("Digite um número válido")
          .required("Campo obrigatório")
          .min(1, "Valor mínimo é 1"),
        unity: string().required("Campo obrigatório"),
      })
    )
    .optional(),
});
