import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";

export const authenticatedAxios = async () => {
  const session = await fetchAuthSession();
  const accessToken = session.tokens?.accessToken.toString();

  if (!accessToken) {
    throw new Error("Access token not found");
  }

  const instance = axios.create();
  instance.defaults.headers.common["Authorization"] = accessToken;

  return instance;
};

export const baseFetch = async <T = object>(
  input: string | URL | globalThis.Request,
  init?: RequestInit
) => {
  return fetch(input, init).then(async (response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    if (await response.clone().text()) {
      return response.json() as T;
    }

    return response.json() as T;
  });
};
