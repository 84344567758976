import axios from "axios";
import { API_URL } from "@/shared/constants/env";
import { PaymentMethodOption } from "@/shared/helpers/company";

export type GetEstablishmentOutput = {
  establishment: {
    pk: string;
    sk: string;
    companyId: string;
    establishmentId: string;
    name: string;
    status: "OPEN" | "CLOSED" | "INACTIVE";
    closeMessage: string;
    createdAt: string;
    workingHour: Record<
      string,
      {
        startTime?: string;
        endTime?: string;
        enabled?: boolean;
        attendingType?: "QUEUE" | "BOOKING" | null;
      }
    >;
    configs: {
      appointmentDuration: string;
    };
    minutesWaitingTimeAverage: string;
    minutesServingTimeAverage: string;
    minutesJourneyTimeAverage: string;
    location?: {
      cep: string;
      state: string;
      city: string;
      neighborhood: string;
      street: string;
      number: string;
      complement?: string;
    };
    paymentMethods: {
      method: PaymentMethodOption;
      complement?: string;
    }[];
    asaasAccount?: {
      id: string;
      walletId: string;
    };
  };
  establishmentSlug: {
    companyId: string;
    establishmentId: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export const getEstablishment = ({
  companyId,
  establishmentId,
}: {
  companyId: string;
  establishmentId: string;
}) => {
  return axios.get<GetEstablishmentOutput>(
    `${API_URL}/public/companies/${companyId}/establishments/${establishmentId}`
  );
};
