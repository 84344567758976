import { useGlobalPageLoading } from "@/contexts/global-page-loading/hook";
import { ROUTES_PATH } from "@/shared/constants/routes";
import { replaceWithObject } from "@/shared/utils/url";
import { ArrowBack } from "@mui/icons-material";
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

export const Header = () => {
  const navigate = useNavigate();
  const { establishmentId } = useParams<{ establishmentId: string }>();
  const { pageTransition } = useGlobalPageLoading();

  if (!establishmentId) {
    throw new Error("establishmentId is required");
  }

  return (
    <AppBar position="sticky">
      <Toolbar>
        <IconButton
          onClick={() =>
            pageTransition(() => {
              navigate(
                replaceWithObject(ROUTES_PATH.ESTABLISHMENT, {
                  establishmentId,
                }),
                { replace: true }
              );
            })
          }
          className="mr-2"
          color="inherit"
          edge="start"
          size="large"
        >
          <ArrowBack />
        </IconButton>

        <Typography variant="h6" component="div" flexGrow={1}>
          Pagamentos
        </Typography>
      </Toolbar>
    </AppBar>
  );
};
