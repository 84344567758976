import { useEstablishmentContext } from "@/contexts/establishment/hook";
import AttendingClientDrawer from "@/shared/drawers/attending-client-drawer";
import { Event } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { useState } from "react";

export const FabCreateBooking = () => {
  const [attendingDrawer, setAttendingDrawer] = useState(false);

  const { establishment } = useEstablishmentContext();

  const openAttendingDrawer = () => {
    setAttendingDrawer(true);
  };

  const closeAttendingDrawer = () => {
    setAttendingDrawer(false);
  };

  return (
    <>
      <Fab
        color="primary"
        aria-label="create-booking"
        className="bottom-20 right-4"
        style={{ position: "fixed" }}
        variant="extended"
        onClick={openAttendingDrawer}
      >
        <Event className="mr-1" />
        Agendar
      </Fab>

      <AttendingClientDrawer
        open={attendingDrawer}
        addedBy="company"
        attendingType="BOOKING"
        establishment={establishment}
        onClose={closeAttendingDrawer}
        onOpen={openAttendingDrawer}
      />
    </>
  );
};
