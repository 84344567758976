import { listLinkInvites } from "@/shared/services/barx-api/companies/link-invites/list";
import { getEstablishment } from "@/shared/services/barx-api/establishments/get";
import { fetchAuthSession, fetchUserAttributes } from "aws-amplify/auth";
import { LoaderFunction } from "react-router-dom";

export const establishmentLoader: LoaderFunction = async (args) => {
  return {
    data: getData(args),
  };
};

const getData: LoaderFunction = async ({ params }) => {
  const { userSub } = await fetchAuthSession();
  const { establishmentId } = params;

  if (!userSub) {
    throw new Error("User not found");
  }

  if (!establishmentId) {
    throw new Error("Establishment not found");
  }

  const { establishment, invite, establishmentSlug } = await getEstablishment({
    companyId: userSub,
    establishmentId,
  })
    .then(({ data }) => ({
      establishment: data.establishment,
      invite: undefined,
      establishmentSlug: data.establishmentSlug,
    }))
    .catch(async () =>
      getLinkedEstablishment({
        establishmentId,
      })
    );

  return { establishment, invite, establishmentSlug };
};

async function getLinkedEstablishment({
  establishmentId,
}: {
  establishmentId: string;
}) {
  const { email } = await fetchUserAttributes();

  if (!email) {
    throw new Error("E-mail not found");
  }

  const invite = await listLinkInvites({ linkedEmail: email }).then(
    ({ data: invites }) =>
      invites.find((invite) => invite.originEstablishmentId === establishmentId)
  );

  if (!invite) return { invite: undefined, establishment: undefined };

  const {
    data: { establishment, establishmentSlug },
  } = await getEstablishment({
    companyId: invite.originCompanyId,
    establishmentId,
  });

  if (!establishment) {
    throw new Error("Establishment not found");
  }

  return { invite, establishment, establishmentSlug };
}
