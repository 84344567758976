import { useGetCustomerPayment } from "@/shared/hooks/establishment/customers/use-get-customer-payment";
import { QueueItem } from "@/shared/services/barx-api/establishments/queue/list";
import { Button, Typography } from "@mui/material";
import { Pix } from "@mui/icons-material";
import { paymentStatusMap } from "@/shared/helpers/payment";

type Props = { queue: QueueItem };

export const PaymentDetail = (props: Props) => {
  const {
    queue: { establishmentId, customerId, payment },
  } = props;

  if (!payment) {
    throw new Error("Payment not found");
  }

  const {
    data: {
      data: {
        order: { status, invoiceUrl },
      },
    },
  } = useGetCustomerPayment({ establishmentId, customerId, payment });

  return (
    <div className="flex flex-col gap-y-1">
      <Typography variant="body2" color="textSecondary">
        Pagamento
      </Typography>
      <Button
        href={invoiceUrl}
        target="_blank"
        color="info"
        startIcon={<Pix />}
        className="w-max"
      >
        {paymentStatusMap[status]}
      </Button>
    </div>
  );
};
