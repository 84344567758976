import { QueueAddedBy, ServiceType } from "@/shared/types/establishments";
import { LinkInvite } from "@/shared/types/invite-link";
import { authenticatedAxios } from "../../fetch";
import { API_URL } from "@/shared/constants/env";
import { PaymentProps } from "../customers/get-payment";

export type QueueItem = {
  companyId: string;
  establishmentId: string;
  customerId: string;
  queueId: string;
  clientName: string;
  serviceTypes: ServiceType[];
  barber: {
    givenName: string;
    familyName: string;
    companyId: string;
  } | null;
  position: number;
  createdAt: string;
  servingAt?: string;
  addedBy: QueueAddedBy;
  status: "WAITING" | "SERVING";
  messagingVapid?: string;
  linkInvite?: LinkInvite;
  payment?: {
    externalChargeId: string;
    createdAt: string;
    status: PaymentProps["status"];
  };
};

type ListEstablishmentQueueOutput = {
  result: QueueItem[];
};

type ListEstablishmentQueueInput = {
  establishmentId: string;
};

export const listEstablishmentQueue = async ({
  establishmentId,
}: ListEstablishmentQueueInput) =>
  (await authenticatedAxios()).get<ListEstablishmentQueueOutput>(
    `${API_URL}/admin/establishments/${establishmentId}/queue`
  );
