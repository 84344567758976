import { Add, Remove } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useIsMutating } from "@tanstack/react-query";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { ServiceTypeInputs } from "./types";
import { TimeField } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { onlyNumbers, toCurrency } from "@/shared/utils";

const DEFAULT_DURATION = new Date(2024, 11, 17, 0, 40, 0, 0);

export const ServiceTypeForm = () => {
  const { register, formState, control } = useFormContext<ServiceTypeInputs>();
  const { fields, append, remove } = useFieldArray({
    name: "consumedItems",
    control,
    shouldUnregister: true,
  });
  const isMutating = useIsMutating() > 0;

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex flex-col gap-y-4">
        <TextField
          {...register("name")}
          label="Serviço"
          autoComplete="service-name"
          placeholder="ex: Barba"
          disabled={isMutating}
          error={!!formState.errors.name}
          helperText={formState.errors.name?.message}
        />

        <TextField
          {...register("amount", {
            onChange(event) {
              const valueAsNumber = Number(
                onlyNumbers(event.target.value ?? "0")
              );
              event.target.value = toCurrency(valueAsNumber ?? 0);
              return event;
            },
          })}
          label="Valor"
          autoComplete="amount"
          slotProps={{ htmlInput: { inputMode: "numeric" } }}
          placeholder="R$ 0,00"
          disabled={isMutating}
          error={!!formState.errors.amount}
          helperText={formState.errors.amount?.message}
        />

        <Controller
          control={control}
          name="duration"
          defaultValue={DEFAULT_DURATION}
          render={({ field }) => {
            return (
              <FormControl>
                <TimeField {...field} label="Duração" format="HH:mm" />
                <FormHelperText>
                  {field.value &&
                    `Duração de ${format(
                      field.value,
                      "HH 'hora(s)' 'e' mm 'minuto(s)'"
                    )}`}
                </FormHelperText>
              </FormControl>
            );
          }}
        />
      </div>

      <div className="flex flex-col gap-y-4">
        <Typography>Produtos consumíveis</Typography>
        <Typography variant="body2" color="textSecondary">
          Adicione produtos que serão consumidos neste serviço.
        </Typography>

        {fields.length ? (
          <div className="flex flex-col gap-y-6">
            {fields.map((field, index) => (
              <div
                key={field.id}
                className="grid grid-rows-2 grid-cols-6 gap-4"
              >
                <TextField
                  {...register(`consumedItems.${index}.name`)}
                  className="col-span-5"
                  label="Produto"
                  autoComplete={`consumed-item-${index}-name`}
                  placeholder="ex: Lâmina de barbear"
                  disabled={isMutating}
                  error={!!formState.errors.consumedItems?.[index]?.name}
                  helperText={
                    formState.errors.consumedItems?.[index]?.name?.message
                  }
                />

                <div className="grid grid-cols-2 col-span-5 gap-2">
                  <TextField
                    {...register(`consumedItems.${index}.quantity`, {
                      valueAsNumber: true,
                    })}
                    label="Quantidade"
                    autoComplete={`consumed-item-${index}-quantity`}
                    slotProps={{ htmlInput: { inputMode: "numeric" } }}
                    placeholder="ex: 1"
                    disabled={isMutating}
                    error={!!formState.errors.consumedItems?.[index]?.quantity}
                    helperText={
                      formState.errors.consumedItems?.[index]?.quantity?.message
                    }
                  />

                  <Controller
                    control={control}
                    name={`consumedItems.${index}.unity`}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id="measure">Medida</InputLabel>
                        <Select
                          {...field}
                          labelId="measure"
                          id="measure"
                          label="Medida"
                        >
                          <MenuItem value="un">(un) unidade</MenuItem>
                          <MenuItem value="ml">(ml) mililitro</MenuItem>
                          <MenuItem value="g">(g) grama</MenuItem>
                        </Select>

                        <FormHelperText error>
                          {
                            formState.errors.consumedItems?.[index]?.unity
                              ?.message
                          }
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>

                <IconButton
                  className="row-span-full justify-self-center self-center"
                  color="error"
                  onClick={() => remove(index)}
                >
                  <Remove />
                </IconButton>
              </div>
            ))}
          </div>
        ) : null}

        <Button
          type="button"
          startIcon={<Add />}
          onClick={() => append({ name: "", quantity: 0, unity: "" })}
        >
          Adicionar consumível
        </Button>
      </div>
    </div>
  );
};
