import { PlayArrow, PersonRemove, CheckCircle } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Chip,
  CircularProgress,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Typography,
} from "@mui/material";
import { QueueItem } from "../../services/barx-api/establishments/queue/list";
import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { useStartServingClientInQueue } from "../../hooks/queue/use-start-serving-client-in-queue";
import { useInvalidateGetEstablishmentQueue } from "../../hooks/queue/use-get-establishment-queue";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { useRemoveClientFromEstablishmentQueue } from "../../hooks/queue/use-remove-client-from-establishment-queue";
import { format, isToday } from "date-fns";
import { toCurrency } from "../../utils";
import { PaymentDetail } from "./payment-detail";
import { ErrorBoundary } from "@sentry/react";
import { Suspense } from "react";

type Props = { queue: QueueItem; onClose(): void } & SwipeableDrawerProps;

export const QueueClientDetailDrawer = ({
  queue,
  onClose,
  ...drawerProps
}: Props) => {
  const { establishmentId, queueId } = queue;

  const { linkInvite, establishment } = useEstablishmentContext();
  const { showSnackbar } = useGlobalSnackbar();

  const { mutate: removeClientMutate, status: removeClientStatus } =
    useRemoveClientFromEstablishmentQueue();

  const { mutate: startServingClient, status: startServingClientStatus } =
    useStartServingClientInQueue({
      establishmentId,
      queueId,
    });

  const { invalidate: invalidateEstablishmentQueue } =
    useInvalidateGetEstablishmentQueue({
      establishmentId,
    });

  const isCreatedAtToday = isToday(queue.createdAt);
  const joinedDateTime = isCreatedAtToday
    ? format(queue.createdAt, "'entrou às' HH:mm")
    : format(queue.createdAt, "dd/MM/yyyy 'às' HH:mm");
  const totalServiceAmount = toCurrency(
    queue.serviceTypes.reduce((prev, curr) => prev + curr.amount, 0)
  );

  const handleRemoveClientFromQueue =
    ({ wasServed }: { wasServed: boolean }) =>
    () => {
      removeClientMutate(
        {
          establishmentId,
          queueId,
          wasServed,
          linkInvite: queue.linkInvite || linkInvite,
        },
        {
          onSuccess() {
            showSnackbar({
              message: "Cliente removido da fila.",
              severity: "success",
            });
            invalidateEstablishmentQueue();
            onClose();
          },
          onError() {
            showSnackbar({
              message:
                "Houve um erro ao remover o cliente da fila, tente novamente.",
              severity: "error",
            });
          },
        }
      );
    };

  const handleStartServingClient = () => {
    startServingClient(
      { linkInvite },
      {
        onSuccess() {
          showSnackbar({
            message: "Atendimento iniciado.",
            severity: "success",
          });
          invalidateEstablishmentQueue();
          onClose();
        },
        onError() {
          showSnackbar({
            message: "Houve um erro ao iniciar o atendimento, tente novamente.",
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <SwipeableDrawer {...drawerProps} onClose={onClose} anchor="bottom">
      <div className="p-4 flex flex-col gap-y-6">
        <header className="flex justify-between items-center gap-x-4">
          <div className="flex flex-col">
            <Typography variant="h6">{queue.clientName}</Typography>
            <Typography>{joinedDateTime}</Typography>
          </div>
          <Chip label={totalServiceAmount} />
        </header>

        <div className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-1">
            <Typography variant="body2" color="textSecondary">
              Serviço escolhido
            </Typography>
            <div className="flex flex-col gap-y-1">
              {queue.serviceTypes.map((service) => (
                <Typography key={service.serviceTypeId}>
                  {service.name}
                </Typography>
              ))}
            </div>
          </div>

          {queue.payment ? (
            <ErrorBoundary
              fallback={
                <Typography color="textSecondary">
                  Houve um problema ao carregar o status do pagamento
                </Typography>
              }
            >
              <Suspense fallback={<CircularProgress className="mx-auto" />}>
                <PaymentDetail queue={queue} />
              </Suspense>
            </ErrorBoundary>
          ) : null}

          {queue.status === "SERVING" ? (
            <Alert severity="info">
              <AlertTitle>Atendimento em andamento</AlertTitle>
              {queue.servingAt ? (
                <b>Iniciou às {format(queue.servingAt, "HH:mm")}.</b>
              ) : null}
            </Alert>
          ) : null}

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-center">
            {queue.status === "WAITING" ? (
              <>
                <LoadingButton
                  fullWidth
                  className="flex-1 sm:max-w-xs justify-self-center"
                  startIcon={<PlayArrow />}
                  color="secondary"
                  variant="contained"
                  loading={startServingClientStatus === "pending"}
                  disabled={startServingClientStatus === "success"}
                  onClick={handleStartServingClient}
                >
                  Iniciar atendimento
                </LoadingButton>
                <LoadingButton
                  fullWidth
                  className="sm:max-w-xs justify-self-center"
                  startIcon={<PersonRemove />}
                  color="error"
                  variant="outlined"
                  loading={removeClientStatus === "pending"}
                  disabled={removeClientStatus === "success"}
                  onClick={handleRemoveClientFromQueue({ wasServed: false })}
                >
                  Retirar da fila
                </LoadingButton>
              </>
            ) : null}
            {queue.status === "SERVING" &&
            (linkInvite
              ? linkInvite?.linkedCompanyId ===
                queue.linkInvite?.linkedCompanyId
              : establishment.companyId === queue.companyId) ? (
              <LoadingButton
                fullWidth
                className="flex-1 sm:max-w-xs justify-self-center"
                startIcon={<CheckCircle />}
                color="success"
                variant="contained"
                loading={removeClientStatus === "pending"}
                disabled={removeClientStatus === "success"}
                onClick={handleRemoveClientFromQueue({ wasServed: true })}
              >
                Finalizar atendimento
              </LoadingButton>
            ) : null}
          </div>
        </div>
      </div>
    </SwipeableDrawer>
  );
};
