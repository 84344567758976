import {
  Alert,
  ButtonBase,
  Card,
  CardContent,
  Chip,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, isToday } from "date-fns";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { toCurrency } from "@/shared/utils";
import { QueueItem } from "@/shared/services/barx-api/establishments/queue/list";
import { QueueClientDetailDrawer } from "@/shared/drawers/queue-client-detail";
import { AttachMoney } from "@mui/icons-material";

type CardItemProps = {
  establishmentQueue: QueueItem;
};

export function CardItem({ establishmentQueue }: CardItemProps) {
  const {
    clientName,
    createdAt,
    servingAt,
    queueId,
    serviceTypes = [],
    barber,
    payment,
  } = establishmentQueue;

  const [queueClientDetailDrawer, setQueueClientDetailDrawer] = useState(false);

  const isCreatedAtToday = isToday(createdAt);
  const joinedDateTime = isCreatedAtToday
    ? format(createdAt, "'entrou às' HH:mm")
    : format(createdAt, "dd/MM/yyyy 'às' HH:mm");
  const startServingAt = servingAt
    ? format(servingAt, "'iniciado às' HH:mm")
    : null;
  const time = servingAt ? startServingAt : joinedDateTime;
  const totalServiceAmount = toCurrency(
    serviceTypes.reduce((prev, curr) => prev + curr.amount, 0)
  );

  const cardClassName = twMerge(
    "group",
    establishmentQueue.status === "SERVING" && "animate-pulse"
  );

  const openQueueClientDetailDrawer = () => {
    setQueueClientDetailDrawer(true);
  };

  const closeQueueClientDetailDrawer = () => {
    setQueueClientDetailDrawer(false);
  };

  return (
    <>
      <Card
        component={ButtonBase}
        variant="outlined"
        key={queueId}
        className={cardClassName}
        onClick={openQueueClientDetailDrawer}
      >
        <CardContent
          className="flex text-left gap-x-4 transition-all w-full"
          data-queue-id={queueId}
        >
          <div className="flex flex-1 flex-col gap-x-4 gap-y-4">
            {!isCreatedAtToday ? (
              <Alert severity="warning">
                <Typography variant="body2">
                  Faz um ou mais dias que este cliente esta na fila.
                </Typography>
              </Alert>
            ) : null}

            <div className="flex justify-between">
              <div>
                <Typography variant="body1" fontWeight="bold">
                  {clientName}
                </Typography>
                <Typography className="text-gray-500">{time}</Typography>
              </div>

              <Tooltip title="Ganho previsto">
                <Chip label={totalServiceAmount} color="default" size="small" />
              </Tooltip>
            </div>

            {payment ? (
              <div className="flex items-center">
                <AttachMoney />
                <Typography>Pagamento online</Typography>
              </div>
            ) : null}

            {barber ? (
              <div>
                <Typography variant="body2" className="text-gray-500">
                  Prefere ser atendido por
                </Typography>
                <Typography variant="body1">
                  {barber.givenName} {barber.familyName}
                </Typography>
              </div>
            ) : null}

            {serviceTypes.length ? (
              <div className="flex flex-wrap gap-2">
                {serviceTypes.map((serviceType) => (
                  <Chip
                    key={serviceType.serviceTypeId}
                    label={serviceType.name}
                    color="default"
                  />
                ))}
              </div>
            ) : null}
          </div>
        </CardContent>
      </Card>

      <QueueClientDetailDrawer
        queue={establishmentQueue}
        onClose={closeQueueClientDetailDrawer}
        open={queueClientDetailDrawer}
        onOpen={openQueueClientDetailDrawer}
      />
    </>
  );
}
