import { API_URL } from "@/shared/constants/env";
import { authenticatedAxios } from "../../fetch";

export type PixAddressKeyTypes = "CPF" | "CNPJ" | "PHONE" | "EMAIL" | "EVP";

export type TransferBalanceInput = {
  establishmentId: string;
  pixAddressKey: string;
  pixAddressKeyType: PixAddressKeyTypes;
};

export type TransferPixOutput = {
  object: "transfer";
  id: string;
  type: "PIX" | "TED" | "INTERNAL";
  dateCreated: string;
  value: number;
  netValue: number;
  status: "PENDING" | "BANK_PROCESSING" | "DONE" | "CANCELLED" | "FAILED";
  transferFee: number;
  effectiveDate: string;
  scheduleDate: string;
  endToEndIdentifier: string | null;
  authorized: boolean;
  failReason: string | null;
  externalReference: string | null;
  transactionReceiptUrl: string | null;
  operationType: "PIX" | "TED";
  description: string | null;
  recurring: boolean | null;
  bankAccount: {
    bank: {
      ispb: string | null;
      code: string;
      name: string;
    };
    accountName: string;
    ownerName: string;
    cpfCnpj: string;
    agency: string;
    agencyDigit: string;
    account: string;
    accountDigit: string;
    pixAddressKey: string | null;
  };
};

export const transferBalance = async (props: TransferBalanceInput) => {
  const { establishmentId, ...body } = props;

  const http = await authenticatedAxios();
  return http.post(
    `${API_URL}/admin/establishments/${establishmentId}/payments/transfer-balance`,
    body
  );
};
