import { useEstablishmentContext } from "@/contexts/establishment/hook";
import {
  Dialog,
  DialogProps,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTransferBalance } from "../hooks/establishment/payments/use-transfer-balance";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { object, ObjectSchema, string } from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PixAddressKeyTypes } from "../services/barx-api/establishments/payments/transfer-balance";
import { LoadingButton } from "@mui/lab";
import { useInvalidateGetTransfers } from "../hooks/establishment/payments/use-get-transfers";
import { useInvalidateGetBalance } from "../hooks/establishment/payments/use-get-balance";

type Props = {} & DialogProps;

type Inputs = {
  pixAddressKey: string;
  pixAddressKeyType: string;
};

const PIX_ADDRESS_KEY_TYPES: PixAddressKeyTypes[] = [
  "CPF",
  "CNPJ",
  "PHONE",
  "EMAIL",
  "EVP",
];

const schema: ObjectSchema<Inputs> = object({
  pixAddressKey: string().required("Campo obrigatório"),
  pixAddressKeyType: string()
    .oneOf<PixAddressKeyTypes>(PIX_ADDRESS_KEY_TYPES, "Tipo inválido")
    .required("Campo obrigatório"),
});

export const TransferBalanceDialog = (props: Props) => {
  const {
    establishment: { establishmentId },
  } = useEstablishmentContext();
  const { mutate, status } = useTransferBalance();
  const { showSnackbar } = useGlobalSnackbar();
  const { register, formState, handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      pixAddressKeyType: "CPF",
      pixAddressKey: "",
    },
  });
  const invalidateGetTransfers = useInvalidateGetTransfers();
  const invalidateGetBalance = useInvalidateGetBalance();

  const submit = handleSubmit(({ pixAddressKey, pixAddressKeyType }) => {
    mutate(
      {
        establishmentId,
        pixAddressKey,
        pixAddressKeyType: pixAddressKeyType as PixAddressKeyTypes,
      },
      {
        async onSuccess() {
          showSnackbar({
            message: "Transferência realizada com sucesso!",
            severity: "success",
          });
          await invalidateGetTransfers({
            establishmentId,
          });
          await invalidateGetBalance({
            establishmentId,
          });
          props.onClose?.({}, "escapeKeyDown");
        },
        onError() {
          showSnackbar({
            message: "Falha ao realizar transferência",
            severity: "error",
          });
        },
      }
    );
  });

  return (
    <Dialog {...props} component="form" onSubmit={submit} fullWidth>
      <div className="flex flex-col gap-y-6 p-4">
        <div className="flex flex-col gap-y-1">
          <Typography variant="h6">Transferir saldo</Typography>
          <Typography variant="body2">
            O saldo será transferido para a chave Pix informada.
          </Typography>
        </div>

        <div className="flex flex-col gap-y-4">
          <Controller
            control={control}
            name="pixAddressKeyType"
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel id="pix-key-type">Tipo da chave</InputLabel>
                <Select
                  {...field}
                  labelId="pix-key-type"
                  id="pix_key_type"
                  label="Tipo da chave"
                  fullWidth
                >
                  {PIX_ADDRESS_KEY_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
                {fieldState.error?.message && (
                  <FormHelperText error>
                    {fieldState.error.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />

          <TextField
            {...register("pixAddressKey")}
            label="Chave PIX"
            fullWidth
            error={!!formState.errors.pixAddressKey}
            helperText={formState.errors.pixAddressKey?.message}
          />
        </div>

        <LoadingButton
          className="self-end"
          type="submit"
          variant="contained"
          loading={status === "pending"}
        >
          Transferir
        </LoadingButton>
      </div>
    </Dialog>
  );
};
