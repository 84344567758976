import { apiAdmin } from "@/shared/services/api";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

type GetTransfersInput = {
  establishmentId: string;
};

export type GetTransfersOutput = {
  object: "transfer";
  id: string;
  type: "PIX" | "TED" | "INTERNAL";
  dateCreated: string;
  value: number;
  netValue: number;
  status: "PENDING" | "BANK_PROCESSING" | "DONE" | "CANCELLED" | "FAILED";
  transferFee: number;
  effectiveDate: string;
  scheduleDate: string;
  endToEndIdentifier: string | null;
  authorized: boolean;
  failReason: string | null;
  externalReference: string | null;
  transactionReceiptUrl: string | null;
  operationType: "PIX" | "TED";
  description: string | null;
  recurring: boolean | null;
  bankAccount: {
    bank: {
      ispb: string | null;
      code: string;
      name: string;
    };
    accountName: string;
    ownerName: string;
    cpfCnpj: string;
    agency: string;
    agencyDigit: string;
    account: string;
    accountDigit: string;
    pixAddressKey: string | null;
  };
};

export type ListTransfersOutput = {
  transfersList: {
    object: "list";
    hasMore: boolean;
    totalCount: number;
    limit: number;
    offset: number;
    data: GetTransfersOutput[];
  };
};

export const useGetTransfers = (props: GetTransfersInput) => {
  const { establishmentId } = props;
  return useSuspenseQuery({
    queryKey: buildQueryKey(props),
    queryFn: async () => {
      const http = await apiAdmin();
      return http.get<ListTransfersOutput>(
        `/establishments/${establishmentId}/payments/transfers`
      );
    },
  });
};

const buildQueryKey = (props: GetTransfersInput) => [
  "use-get-transfers",
  ...Object.values(props),
];

export const useInvalidateGetTransfers = () => {
  const queryClient = useQueryClient();

  return async (props: GetTransfersInput) =>
    queryClient.invalidateQueries({
      queryKey: buildQueryKey(props),
    });
};
