import { apiAdmin } from "@/shared/services/api";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";

type GetBalanceInput = {
  establishmentId: string;
};

type GetBalanceOutput = {
  balance: number;
};

export const useGetBalance = (props: GetBalanceInput) => {
  const { establishmentId } = props;
  return useSuspenseQuery({
    queryKey: buildQueryKey(props),
    queryFn: async () => {
      const http = await apiAdmin();
      return http.get<GetBalanceOutput>(
        `/establishments/${establishmentId}/payments/balance`
      );
    },
  });
};

const buildQueryKey = (props: GetBalanceInput) => [
  "get-balance",
  ...Object.values(props),
];

export const useInvalidateGetBalance = () => {
  const queryClient = useQueryClient();
  return (props: GetBalanceInput) =>
    queryClient.invalidateQueries({
      queryKey: buildQueryKey(props),
    });
};
