import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import {
  CheckDocumentsOutput,
  useCheckDocuments,
} from "@/shared/hooks/establishment/payments/use-check-documents";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const documentStatusMap = {
  NOT_SENT: "Não enviado",
  PENDING: "Pendente",
  APPROVED: "Aprovado",
  REJECTED: "Rejeitado",
  IGNORED: "Ignorado",
};

export const CheckDocuments = () => {
  const [documents, setDocuments] = useState<CheckDocumentsOutput>();
  const {
    establishment: { establishmentId },
  } = useEstablishmentContext();
  const { mutate } = useCheckDocuments();
  const { showSnackbar } = useGlobalSnackbar();

  useEffect(() => {
    mutate(
      { establishmentId },
      {
        onSuccess(data) {
          setDocuments(data.data);
        },
        onError() {
          showSnackbar({
            message: "Erro ao verificar documentos",
            severity: "error",
          });
        },
      }
    );
  }, [mutate, establishmentId, showSnackbar]);

  return (
    <>
      {documents?.rejectReasons ? (
        <Alert severity="warning">
          <AlertTitle>Documentos rejeitados</AlertTitle>
          <Typography>{documents.rejectReasons}</Typography>
        </Alert>
      ) : null}

      {documents?.data
        .filter((document) => document.status !== "APPROVED")
        .map((document) => (
          <Alert
            key={document.id}
            severity="warning"
            action={
              <Button
                target="_blank"
                href={document.onboardingUrl}
                rel="noreferrer"
              >
                Enviar documento
              </Button>
            }
          >
            <AlertTitle>{document.title}</AlertTitle>
            <Typography>{document.description}</Typography>
            <Typography fontWeight="bold">
              {documentStatusMap[document.status]}
            </Typography>
          </Alert>
        ))}
    </>
  );
};
