import { FC, Suspense } from "react";
import {
  CircularProgress,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Typography,
} from "@mui/material";
import {
  Establishment,
  QueueAddedBy,
  WorkingHourItem,
} from "@/shared/types/establishments";
import AddClientQueue from "./add-client-queue";
import AddClientBooking from "./add-client-booking";

type Props = {
  open: boolean;
  onClose(): void;
  establishment: Establishment;
  attendingType: WorkingHourItem[string]["attendingType"];
  addedBy: QueueAddedBy;
  withSignIn?: boolean;
} & SwipeableDrawerProps;

export const AttendingClientDrawer: FC<Props> = ({
  open,
  onClose,
  establishment,
  attendingType,
  addedBy,
  ...drawerProps
}) => {
  function handleClose() {
    onClose();
  }

  const handleEnterQueue = () => {
    handleClose();
  };

  return (
    <SwipeableDrawer
      open={open}
      onClose={handleClose}
      anchor="bottom"
      {...drawerProps}
    >
      <div className="p-4 flex flex-col gap-y-6">
        <header className="flex justify-between items-center">
          <Typography variant="h6">
            {attendingType === "QUEUE"
              ? "Adicionar na fila"
              : "Reservar horário para atendimento"}
          </Typography>
        </header>

        <>
          {attendingType === "QUEUE" ? (
            <Suspense fallback={<CircularProgress className="mx-auto" />}>
              <AddClientQueue
                addedBy={addedBy}
                establishment={establishment}
                onFinish={handleEnterQueue}
              />
            </Suspense>
          ) : null}

          {attendingType === "BOOKING" ? (
            <Suspense fallback={<CircularProgress className="mx-auto" />}>
              <AddClientBooking
                establishment={establishment}
                onFinish={handleClose}
              />
            </Suspense>
          ) : null}
        </>
      </div>
    </SwipeableDrawer>
  );
};

export default AttendingClientDrawer;
