import { apiAdmin } from "@/shared/services/api";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

type CreateSubAccountInput = {
  establishmentId: string;
  document: string;
  birthdate: string;
  monthly_income: number;
};

type CreateSubAccountOutput = {
  object: "account";
  id: string;
  name: string;
  email: string;
  loginEmail: string;
  phone: string | null;
  mobilePhone: string | null;
  address: string;
  addressNumber: string;
  complement: string | null;
  province: string;
  postalCode: string;
  cpfCnpj: string;
  birthDate: string;
  personType: "JURIDICA" | "FISICA";
  companyType: string;
  city: number;
  state: string;
  country: string;
  tradingName: string | null;
  site: string;
  walletId: string;
  accountNumber: {
    agency: string;
    account: string;
    accountDigit: string;
  };
  commercialInfoExpiration: {
    isExpired: boolean;
    scheduledDate: string;
  };
  apiKey: string;
};

export const useCreateSubAccount = () =>
  useMutation<
    AxiosResponse<CreateSubAccountOutput>,
    AxiosError,
    CreateSubAccountInput
  >({
    mutationFn: async ({ establishmentId, ...data }) => {
      const http = await apiAdmin();
      return http.post(
        `establishments/${establishmentId}/payments/sub-accounts`,
        data
      );
    },
  });
