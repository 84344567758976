import { apiAdmin } from "@/shared/services/api";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

type CheckDocumentsInput = {
  establishmentId: string;
};

export type CheckDocumentsOutput = {
  rejectReasons: string | null;
  data: Array<{
    id: string;
    status: "NOT_SENT" | "PENDING" | "APPROVED" | "REJECTED" | "IGNORED";
    type:
      | "IDENTIFICATION"
      | "SOCIAL_CONTRACT"
      | "ENTREPRENEUR_REQUIREMENT"
      | "MINUTES_OF_ELECTION"
      | "CUSTOM";
    title: string;
    description: string;
    responsible: {
      name: string;
      type:
        | "ALLOW_BANK_ACCOUNT_DEPOSIT_STATEMENT"
        | "ASAAS_ACCOUNT_OWNER_EMANCIPATION_AGE"
        | "ASAAS_ACCOUNT_OWNER"
        | "ASSOCIATION"
        | "BANK_ACCOUNT_OWNER_EMANCIPATION_AGE"
        | "BANK_ACCOUNT_OWNER"
        | "CUSTOM"
        | "DIRECTOR"
        | "INDIVIDUAL_COMPANY"
        | "LIMITED_COMPANY"
        | "MEI"
        | "PARTNER";
    };
    onboardingUrl: string;
    documents: Array<{
      id: string;
      status: "NOT_SENT" | "PENDING" | "APPROVED" | "REJECTED";
    }>;
  }>;
};

export const useCheckDocuments = () =>
  useMutation<
    AxiosResponse<CheckDocumentsOutput>,
    AxiosError,
    CheckDocumentsInput
  >({
    mutationFn: async ({ establishmentId }) => {
      const http = await apiAdmin();
      return http.post(
        `/establishments/${establishmentId}/payments/sub-accounts/documents`
      );
    },
  });
