import { websocketClient } from "@/shared/services/api";
import { QueueItem } from "@/shared/services/barx-api/establishments/queue/list";
import { useCallback, useEffect, useState } from "react";

export type ConnectionState = "CONNECTED" | "DISCONNECTED" | "LOADING";

type Params = {
  establishmentId: string;
};

export function useRealtimeEstablishmentQueue({ establishmentId }: Params) {
  const [establishmentQueue, setEstablishmentQueue] = useState<QueueItem[]>();
  const [connectionState, setConnectionState] =
    useState<ConnectionState>("LOADING");
  const [hadData, setHadData] = useState(false);
  const [socket, setSocket] = useState<WebSocket>();

  function handleOpenSocket() {
    setConnectionState("CONNECTED");
  }

  function handleCloseSocket() {
    setConnectionState("DISCONNECTED");
  }

  function handleMessageSocket(message: MessageEvent<string>) {
    const data = JSON.parse(message.data) as QueueItem[];
    setEstablishmentQueue(data);
  }

  const addSocketListeners = useCallback((socket: WebSocket) => {
    socket.addEventListener("open", handleOpenSocket);
    socket.addEventListener("close", handleCloseSocket);
    socket.addEventListener("message", handleMessageSocket);
  }, []);

  const closeSocket = useCallback((socket: WebSocket) => {
    socket.close();
    socket.removeEventListener("open", handleOpenSocket);
    socket.removeEventListener("close", handleCloseSocket);
    socket.removeEventListener("message", handleMessageSocket);
  }, []);

  const reconnect = useCallback(() => {
    setConnectionState("LOADING");

    if (socket) {
      closeSocket(socket);
    }

    const socketInstance = websocketClient(establishmentId);
    setSocket(socketInstance);
  }, [establishmentId, socket, closeSocket]);

  useEffect(() => {
    if (establishmentQueue) {
      setHadData(true);
    }
  }, [establishmentQueue]);

  useEffect(() => {
    if (socket) return;

    const socketInstance = websocketClient(establishmentId);
    setSocket(socketInstance);

    return () => {
      closeSocket(socketInstance);
    };
  }, [socket, establishmentId, closeSocket]);

  useEffect(() => {
    if (!socket) return;

    addSocketListeners(socket);

    return () => {
      closeSocket(socket);
    };
  }, [socket, closeSocket, addSocketListeners]);

  useEffect(() => {
    if (connectionState === "DISCONNECTED") {
      reconnect();
    }
  }, [connectionState, reconnect]);

  return {
    establishmentQueue,
    connectionState,
    hadData,
  };
}
