import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import EditServiceTypeDrawer from "@/shared/drawers/service-types/edit-service-type";
import { useCurrentUser } from "@/shared/hooks/auth/use-user-attributes";
import { useDeleteServiceType } from "@/shared/hooks/establishment/service-types/use-delete-service-type";
import { useInvalidateListServiceTypes } from "@/shared/hooks/establishment/service-types/use-list-service-types";
import { ListServiceTypeOutput } from "@/shared/services/barx-api/establishments/service-types/list";
import { ServiceType } from "@/shared/types/establishments";
import { toCurrency } from "@/shared/utils";
import { Delete, Edit } from "@mui/icons-material";
import {
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Suspense, useState } from "react";

type CardItemsProps = {
  serviceType: ServiceType;
  establishmentId: string;
};

export const CardItems = ({ serviceType, establishmentId }: CardItemsProps) => {
  const [editServiceType, setEditServiceType] =
    useState<ListServiceTypeOutput["serviceTypes"][number]>();
  const { data } = useCurrentUser();
  const companyId = data.userId;
  const { showSnackbar } = useGlobalSnackbar();
  const { mutate: deleteServiceType, isPending: isServiceTypeDeletePending } =
    useDeleteServiceType();
  const invalidateListServiceTypesQuery = useInvalidateListServiceTypes();

  const handleEditServiceType = (
    serviceType: ListServiceTypeOutput["serviceTypes"][number]
  ) => {
    setEditServiceType(serviceType);
  };

  const closeEditServiceTypeDrawer = () => {
    setEditServiceType(undefined);
  };

  const handleDeleteServiceType = (serviceTypeId: string) => {
    showSnackbar({
      message: `Removendo o serviço ${serviceType.name}...`,
      severity: "info",
    });

    deleteServiceType(
      { establishmentId, serviceTypeId },
      {
        onSuccess() {
          showSnackbar({
            message: "Serviço removido com sucesso.",
            severity: "success",
          });
          invalidateListServiceTypesQuery({ companyId, establishmentId });
        },
        onError() {
          showSnackbar({
            message: "Erro ao remover serviço, tente novamente.",
            severity: "error",
          });
        },
      }
    );
  };

  return (
    <>
      <Card
        key={serviceType.serviceTypeId}
        className={isServiceTypeDeletePending ? "opacity-30" : ""}
      >
        <CardContent className="flex justify-between gap-x-4">
          <div>
            <Typography variant="body1" fontWeight="bold">
              {serviceType.name}
            </Typography>
            <Typography variant="body2" className="text-gray-500">
              {toCurrency(serviceType.amount)}
            </Typography>
          </div>

          <div className="self-center flex">
            <Tooltip title="Alterar serviço" placement="top">
              <IconButton
                disabled={isServiceTypeDeletePending}
                onClick={() => handleEditServiceType(serviceType)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Excluir serviço" placement="top">
              <IconButton
                color="error"
                disabled={isServiceTypeDeletePending}
                onClick={() =>
                  handleDeleteServiceType(serviceType.serviceTypeId)
                }
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        </CardContent>
      </Card>

      <Suspense>
        <EditServiceTypeDrawer
          establishmentId={establishmentId}
          onClose={closeEditServiceTypeDrawer}
          open={!!editServiceType}
          serviceType={editServiceType}
        />
      </Suspense>
    </>
  );
};
