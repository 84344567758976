import { ROUTES_PATH } from "@/shared/constants/routes";
import { fetchUserAttributes } from "aws-amplify/auth";
import { redirect } from "react-router-dom";

export const adminLayoutLoader = async () => {
  return {
    userAttributes: getData(),
  };
};

const getData = async () => {
  try {
    const userAttributes = await fetchUserAttributes();
    return userAttributes;
  } catch {
    return redirect(ROUTES_PATH.AUTH);
  }
};
