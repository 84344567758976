import { useEstablishmentContext } from "@/contexts/establishment/hook";
import {
  transferStatusMap,
  transferStatusMapToChipColor,
} from "@/shared/helpers/payment";
import { useGetTransfers } from "@/shared/hooks/establishment/payments/use-get-transfers";
import { toCurrency } from "@/shared/utils";
import {
  Chip,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { format, parse } from "date-fns";

export const ListTransfers = () => {
  const {
    establishment: { establishmentId },
  } = useEstablishmentContext();
  const {
    data: {
      data: {
        transfersList: { data: transfers },
      },
    },
  } = useGetTransfers({ establishmentId });

  return (
    <Paper elevation={0} className="flex flex-col mx-4">
      <Typography variant="h6">Transferências</Typography>

      {transfers.length ? (
        <List>
          {transfers.map((transfer) => (
            <ListItem key={transfer.id}>
              <ListItemText
                primary={
                  <div>
                    <Typography variant="body1">
                      {format(
                        parse(transfer.dateCreated, "yyyy-MM-dd", new Date()),
                        "dd/MM/yyyy"
                      )}
                      {" - "}
                      {toCurrency(transfer.value * 100)}
                    </Typography>
                  </div>
                }
                secondary={transfer.description || "Transferência"}
              />
              <Chip
                color={transferStatusMapToChipColor[transfer.status]}
                label={transferStatusMap[transfer.status]}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography
          className="text-center pt-2"
          variant="body2"
          color="textSecondary"
        >
          Nenhuma transferência realizada
        </Typography>
      )}
    </Paper>
  );
};
