import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  TextField,
} from "@mui/material";

import InputMask from "@mona-health/react-input-mask";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, ObjectSchema, string } from "yup";
import { toCurrency } from "@/shared/utils";
import { useCreateSubAccount } from "@/shared/hooks/establishment/payments/use-create-sub-account";
import { LoadingButton } from "@mui/lab";
import { useGlobalSnackbar } from "@/contexts/global-snackbar/hook";
import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { format, parse } from "date-fns";
import { useInvalidateGetEstablishment } from "@/shared/hooks/establishment/use-get-establishment";

type Props = { onClose(): void } & DialogProps;

type FormValues = {
  cpf: string;
  birthdate: string;
  monthlyIncome: string;
};

const cpfRegex = /^(\d{3}\.){2}\d{3}-\d{2}$/;
const birthDateRegex = /^(\d{2}\/){2}\d{4}$/;

const schema: ObjectSchema<FormValues> = object({
  cpf: string().matches(cpfRegex, "CPF inválido").required("Campo obrigatório"),
  birthdate: string()
    .matches(birthDateRegex, "Formato inválido")
    .required("Campo obrigatório"),
  monthlyIncome: string().required("Campo obrigatório"),
});

const onlyNumbers = (value: string): number => Number(value.replace(/\D/g, ""));

export const DialogCreateSubAccount = ({ onClose, ...props }: Props) => {
  const { handleSubmit, register, control, formState, reset } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });
  const { mutate, status } = useCreateSubAccount();
  const { showSnackbar } = useGlobalSnackbar();
  const {
    establishment: { establishmentId, companyId },
  } = useEstablishmentContext();
  const invalidateGetEstablishment = useInvalidateGetEstablishment();

  return (
    <Dialog
      {...props}
      fullWidth
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit((data) => {
          const birthdate = format(
            parse(data.birthdate, "dd/MM/yyyy", new Date()),
            "yyyy-MM-dd"
          );
          const document = String(onlyNumbers(data.cpf));
          const monthly_income = onlyNumbers(data.monthlyIncome) / 100;

          mutate(
            { establishmentId, birthdate, document, monthly_income },
            {
              async onSuccess() {
                reset();
                showSnackbar({
                  message:
                    "Conta criada com sucesso, verifique seu e-mail para continuar seu cadastro.",
                  severity: "success",
                });
                await invalidateGetEstablishment({
                  companyId,
                  establishmentId,
                });
                onClose();
              },
              onError() {
                showSnackbar({
                  message: "Erro ao criar conta, tente novamente.",
                  severity: "error",
                });
              },
            }
          );
        }),
      }}
    >
      <DialogTitle>Criar conta Asaas</DialogTitle>
      <DialogContent className="flex flex-col gap-y-4">
        <InputMask {...register("cpf")} mask="999.999.999-99" maskChar={null}>
          <TextField
            label="CPF"
            variant="standard"
            slotProps={{ input: { inputMode: "numeric" } }}
            error={!!formState.errors.cpf?.message}
            helperText={formState.errors.cpf?.message}
          />
        </InputMask>

        <InputMask {...register("birthdate")} mask="99/99/9999" maskChar={null}>
          <TextField
            label="Data de nascimento"
            variant="standard"
            slotProps={{ input: { inputMode: "numeric" } }}
            error={!!formState.errors.birthdate?.message}
            helperText={formState.errors.birthdate?.message}
          />
        </InputMask>

        <Controller
          control={control}
          name="monthlyIncome"
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => {
                e.currentTarget.value = String(
                  onlyNumbers(e.currentTarget.value)
                );
                field.onChange(e);
              }}
              value={
                field.value ? toCurrency(onlyNumbers(field.value)) : undefined
              }
              label="Renda mensal"
              variant="standard"
              slotProps={{ input: { inputMode: "numeric" } }}
              error={!!formState.errors.monthlyIncome?.message}
              helperText={formState.errors.monthlyIncome?.message}
            />
          )}
        />
      </DialogContent>

      <DialogActions>
        <LoadingButton loading={status === "pending"} type="submit">
          Criar conta
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
