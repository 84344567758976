import { useEffect, useState } from "react";
import { useGetEstablishmentQueue } from "./use-get-establishment-queue";
import { useRealtimeEstablishmentQueue } from "./use-realtime-establishment-queue";
import { QueueItem } from "@/shared/services/barx-api/establishments/queue/list";

type Params = {
  establishmentId: string;
};

export function useWatchEstablishmentQueue({ establishmentId }: Params) {
  const [queue, setQueue] = useState<QueueItem[]>([]);
  const { data, status, refetch } = useGetEstablishmentQueue({
    establishmentId,
  });
  const { establishmentQueue, connectionState } = useRealtimeEstablishmentQueue(
    { establishmentId }
  );

  useEffect(() => {
    if (!data) return;
    setQueue(data.data.result);
  }, [data]);

  useEffect(() => {
    if (!establishmentQueue) return;
    setQueue(establishmentQueue);
  }, [establishmentQueue]);

  return { establishmentQueue: queue, connectionState, status, refetch };
}
