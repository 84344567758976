import {
  getCustomerPayment,
  GetCustomerPaymentInput,
} from "@/shared/services/barx-api/establishments/customers/get-payment";
import { useSuspenseQuery } from "@tanstack/react-query";

type Params = {} & GetCustomerPaymentInput;

export const useGetCustomerPayment = (props: Params) => {
  return useSuspenseQuery({
    queryKey: buildQueryKey(props),
    queryFn: () => getCustomerPayment(props),
  });
};

const buildQueryKey = (props: Params) => [
  "get-customer-payment",
  ...Object.values(props),
];
