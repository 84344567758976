import { API_URL } from "@/shared/constants/env";
import { authenticatedAxios } from "../../fetch";

export type PaymentProps = {
  companyId: string;
  establishmentId: string;
  customerId: string;
  externalChargeId: string;
  createdAt: string;
  items: Array<{
    description: string;
    amount: number;
    quantity: number;
  }>;
  status: CreateChargeOutput["status"];
};

export type CreateChargeOutput = {
  object: "payment";
  id: string;
  dateCreated: string;
  customer: string;
  subscription: string | null;
  installment: string | null;
  paymentLink: string | null;
  value: number;
  netValue: number;
  originalValue: number | null;
  interestValue: number | null;
  description: string;
  billingType:
    | "UNDEFINED"
    | "BOLETO"
    | "CREDIT_CARD"
    | "DEBIT_CARD"
    | "TRANSFER"
    | "DEPOSIT"
    | "PIX";
  creditCard: {
    creditCardNumber: string;
    creditCardBrand: string;
    creditCardToken: string | null;
  };
  canBePaidAfterDueDate: boolean;
  pixTransaction: string | null;
  pixQrCodeId: string | null;
  status:
    | "PENDING"
    | "RECEIVED"
    | "CONFIRMED"
    | "OVERDUE"
    | "REFUNDED"
    | "RECEIVED_IN_CASH"
    | "REFUND_REQUESTED"
    | "REFUND_IN_PROGRESS"
    | "CHARGEBACK_REQUESTED"
    | "CHARGEBACK_DISPUTE"
    | "AWAITING_CHARGEBACK_REVERSAL"
    | "DUNNING_REQUESTED"
    | "DUNNING_RECEIVED"
    | "AWAITING_RISK_ANALYSIS"
    | "CANCELLED";
  dueDate: string;
  originalDueDate: string;
  paymentDate: string | null;
  clientPaymentDate: string | null;
  installmentNumber: number | null;
  invoiceUrl: string;
  invoiceNumber: string;
  externalReference: string;
  deleted: boolean;
  anticipated: boolean;
  anticipable: boolean;
  creditDate: string;
  estimatedCreditDate: string;
  transactionReceiptUrl: string | null;
  nossoNumero: string;
  bankSlipUrl: string;
  discount: {
    value: number;
    dueDateLimitDays: number;
    type: "PERCENTAGE" | "VALUE";
  };
  fine: {
    value: number;
  };
  interest: {
    value: number;
  };
  split: {
    id: string;
    walletId: string;
    fixedValue: number | null;
    percentualValue: number | null;
    totalValue: number;
    cancellationReason: string;
    status: "PENDING" | "PAID" | "CANCELLED";
    externalReference: string | null;
    description: string | null;
  }[];
  postalService: boolean;
  daysAfterDueDateToRegistrationCancellation: number | null;
  chargeback: {
    id: string;
    payment: string;
    installment: string;
    customerAccount: string;
    status: "DONE" | "PENDING" | "CANCELLED";
    reason: string;
    disputeStartDate: string;
    value: number;
    paymentDate: string;
    creditCard: {
      number: string;
      brand: string;
    };
    disputeStatus: "ACCEPTED" | "REJECTED" | "PENDING";
    deadlineToSendDisputeDocuments: string;
  };
  refunds: {
    dateCreated: string;
    status: "DONE" | "PENDING" | "CANCELLED";
    value: number;
    endToEndIdentifier: string | null;
    description: string | null;
    effectiveDate: string;
    transactionReceiptUrl: string | null;
    refundedSplits: {
      id: string;
      value: number;
      done: boolean;
    }[];
  }[];
};

export type GetCustomerPaymentOutput = {
  payment: PaymentProps;
  order: CreateChargeOutput;
};

export type GetCustomerPaymentInput = {
  establishmentId: string;
  customerId: string;
  payment: {
    createdAt: string;
    externalChargeId: string;
  };
};

export const getCustomerPayment = async (input: GetCustomerPaymentInput) => {
  const { establishmentId, customerId, payment } = input;
  const paymentId = `${payment.externalChargeId}~${payment.createdAt}`;

  return (await authenticatedAxios()).get<GetCustomerPaymentOutput>(
    `${API_URL}/admin/establishments/${establishmentId}/customers/${customerId}/payments/${paymentId}`
  );
};
