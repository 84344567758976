import { ChipProps } from "@mui/material";
import { GetTransfersOutput } from "../hooks/establishment/payments/use-get-transfers";
import { PaymentProps } from "../services/barx-api/establishments/customers/get-payment";

export const transferStatusMap: Record<GetTransfersOutput["status"], string> = {
  PENDING: "Pendente",
  BANK_PROCESSING: "Processando",
  DONE: "Concluída",
  CANCELLED: "Cancelada",
  FAILED: "Falha",
};

export const transferStatusMapToChipColor: Record<
  GetTransfersOutput["status"],
  ChipProps["color"]
> = {
  PENDING: "warning",
  BANK_PROCESSING: "warning",
  DONE: "success",
  CANCELLED: "error",
  FAILED: "error",
};

export const paymentStatusMap: Record<PaymentProps["status"], string> = {
  PENDING: "Aguardando pagamento",
  RECEIVED: "Pago",
  CONFIRMED: "Confirmado",
  OVERDUE: "Atrasado",
  REFUNDED: "Estornado",
  RECEIVED_IN_CASH: "Pago em dinheiro",
  REFUND_REQUESTED: "Estorno solicitado",
  REFUND_IN_PROGRESS: "Estorno em andamento",
  CHARGEBACK_REQUESTED: "Chargeback solicitado",
  CHARGEBACK_DISPUTE: "Disputa de chargeback",
  AWAITING_CHARGEBACK_REVERSAL: "Aguardando reversão de chargeback",
  DUNNING_REQUESTED: "Dunning solicitado",
  DUNNING_RECEIVED: "Dunning recebido",
  AWAITING_RISK_ANALYSIS: "Aguardando análise de risco",
  CANCELLED: "Cancelado",
};

export const paymentStatusColorMap: Record<
  PaymentProps["status"],
  ChipProps["color"]
> = {
  PENDING: "warning",
  RECEIVED: "success",
  CONFIRMED: "success",
  OVERDUE: "error",
  REFUNDED: "error",
  RECEIVED_IN_CASH: "success",
  REFUND_REQUESTED: "warning",
  REFUND_IN_PROGRESS: "warning",
  CHARGEBACK_REQUESTED: "warning",
  CHARGEBACK_DISPUTE: "error",
  AWAITING_CHARGEBACK_REVERSAL: "warning",
  DUNNING_REQUESTED: "warning",
  DUNNING_RECEIVED: "warning",
  AWAITING_RISK_ANALYSIS: "warning",
  CANCELLED: "default",
};
