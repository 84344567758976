import { Header } from "../components/header";
import { useEstablishmentContext } from "@/contexts/establishment/hook";
import { CardSignUpAsaas } from "../onboarding/card-sign-up-asaas";
import { Balance } from "../balance";
import { ErrorBoundary } from "@sentry/react";
import { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import { CheckDocuments } from "../check-documents";
import { ListTransfers } from "../list-transfers";

export const EstablishmentPaymentMethod = () => {
  const { establishment } = useEstablishmentContext();
  const hasAsaasAccount = !!establishment.asaasAccount?.id;
  console.log(
    "🚀 ~ EstablishmentPaymentMethod ~ establishment:",
    establishment
  );

  return (
    <>
      <Header />

      {hasAsaasAccount ? (
        <div className="flex flex-col gap-y-6">
          <ErrorBoundary>
            <CheckDocuments />
          </ErrorBoundary>

          <ErrorBoundary>
            <Suspense fallback={<CircularProgress className="mx-auto" />}>
              <Balance />
            </Suspense>
          </ErrorBoundary>

          <ErrorBoundary>
            <Suspense fallback={<CircularProgress className="mx-auto" />}>
              <ListTransfers />
            </Suspense>
          </ErrorBoundary>
        </div>
      ) : (
        <CardSignUpAsaas />
      )}
    </>
  );
};
